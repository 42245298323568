import request from '@/utils/requesnetcore'

/**
 * 获取资源文件
 * @param {*} data 
 */

 export function getlyqimgage(classid) {
    return request({
        url: '/api/xiaoxiannvappservies/getlyqimgage?classid='+classid,
        method: 'post',
    })
}


//获取分类
export function getclass()
{
    return request({
        url: 'api/xiaoxiannvappservies/class',
        method: 'get',
    })
}
