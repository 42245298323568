import axios from 'axios'
import store from '@/store'
import { MessageBox, Notification, Message } from 'element-ui'
import { getToken } from '@/utils/auth'

import router from '@/router'
import qs from 'qs';

// create an axios instance
const service = axios.create({
    // // http://192.168.3.8:8888/ baseURL: 'http://api.clound.ltd/',
    baseURL: 'https://api.clound.ltd/',
    timeout: 100000, // request timeout
    responseType:'json',
    headers: {
        'Content-Type': 'application/json'
    },
    transformRequest: [function (data) {

        return JSON.stringify(data);
        // if (Object.prototype.toString.call(data) === '[object FormData]' || !data) {
        //     return data
        // } else {
        //     const formData = new FormData()
        //     Object.keys(data).forEach((key) => {
        //         formData.append(key, data[key])
        //     })
        //     return formData
        // }
    }]
})

// request interceptor
service.interceptors.request.use(
    config => {
        // do something before request is sent
        const hasToken = getToken()//获取当前token 
        if (hasToken) {
            config.headers['Authorization'] = getToken() 
        }
        return config
    },
    error => {
         
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {

        if (response.data.statusCode == 401 || response.data.statusCode == 403)
        {
            store.dispatch('logout').then(() => {
                Notification.error({
                    title: response.data.rspData,
                    message: '用户过期,请重新登录',
                });
                router.push('/')
            })
        }
        const res = response.data.result
        if (res.statuscode == "20031") {
            Message({
                message: res.rspdata,
                type: 'error',
                duration: 3 * 1000
            })
            return Promise.reject(new Error(res.rspdata || 'Error'))
        }
        return res
        // store.commit('SET_LOADING',false);

        // if the custom code is not 20000, it is judged as an error.
        // if (res.code !== 20000) {
        //     return Promise.reject(new Error(res.message || 'Error'))
        // } else {
        //     return res
        // }
    },
    error  => {
        if (error.response==undefined)
        {
            Message({
                message: "请求过于频繁,请等待",
                type: 'error',
                duration: 3 * 1000
            })
            return
        }
        if (error.response.status == 403 || error.response.status == 401) {

            // Message({
            //     message: "用户已过期,请重新登录",
            //     type: 'error',
            //     duration: 3 * 1000
            // })
            // store.dispatch('logout');
            // router.push({ path: "/" });

            store.dispatch('logout').then(() => {
                router.push('/')
            })
            return;
            // return Promise.reject(error)
        }
        return Promise.reject(error)
    }
)

export default service
