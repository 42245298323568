<template>
  <div class="home">
    <el-row>
      <el-col :span="24">
        <div class="ringht">
          <el-select v-model="value" @change="change" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.classname"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <div class="container">
          <div
            @click="dlogs(count.url)"
            class="img-wrap"
            v-for="count in datadiv"
            :key="count"
          >
            <img :src="count.url" />
          </div>
        </div>
      </el-col>
    </el-row>

    <el-dialog :visible.sync="dialogVisible" width="30%">
      <img width="100%" :src="imgurl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import { getclass, getlyqimgage } from "../api/imgage";
export default {
  name: "Home",
  data() {
    return {
      dialogVisible: false,
      options: [],
      value: "",
      imgurl: "",
      datadiv: [],
    };
  },
  methods: {
    dlogs(data) {
      this.imgurl = data;
      this.dialogVisible = true;
    },
    change(value) {
      this.getlyqimgageasync(value);
    },
    getlyqimgageasync(classid) {
      getlyqimgage(classid).then((res) => {
        this.datadiv = res.rspdata;
      });
    },
    getclassasync() {
      getclass().then((res) => {
        this.options = res.rspdata;
        this.value = this.options[0].id;
        this.getlyqimgageasync(this.value);
      });
    },
  },
  mounted() {},
  created() {
    this.getclassasync();
  },
};
</script>
<style scoped lang="less">
.home {
  margin-top: 15px;
  .ringht {
    float: right;
    margin-right: 20px;
  }
}
.container {
  width: 90%;
  height: auto;
  margin: 6.25rem auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.container .img-wrap {
  padding: 10px;
  margin: 10px;
  background: white;
  border: 1px solid #ddd;
  width: 160px;
  height: fit-content;
  box-shadow: 2px 2px 3px rgba(50, 50, 50, 0.4);
  -webkit-transition: all 0.5s ease-in;
  -moz-transition: all 0.5s ease-in;
  -ms-transition: all 0.5s ease-in;
  -o-transition: all 0.5s ease-in;
  transition: all 0.5s ease-in;
  z-index: 1;
}

.container .img-wrap img {
  max-width: 100%;
  height: auto;
}

.container .img-wrap:nth-child(1n) {
  transform: rotate(-5deg);
  -webkit-transform: rotate(-5deg);
}

.container .img-wrap:nth-child(2n) {
  transform: rotate(-20deg);
  -webkit-transform: rotate(-20deg);
}

.container .img-wrap:nth-child(3n) {
  transform: rotate(5deg);
  -webkit-transform: rotate(5deg);
}

.container .img-wrap:nth-child(4n) {
  transform: rotate(-10deg);
  -webkit-transform: rotate(-10deg);
}

.container .img-wrap:nth-child(5n) {
  transform: rotate(-30deg);
  -webkit-transform: rotate(-30deg);
}

.container .img-wrap:nth-child(6n) {
  transform: rotate(20deg);
  -webkit-transform: rotate(20deg);
}

.container .img-wrap:nth-child(7n) {
  transform: rotate(50deg);
  -webkit-transform: rotate(50deg);
}

.container .img-wrap:nth-child(8n) {
  transform: rotate(5deg);
  -webkit-transform: rotate(5deg);
}

.container .img-wrap:nth-child(9n) {
  transform: rotate(15deg);
  -webkit-transform: rotate(15deg);
}

.container .img-wrap:nth-child(10n) {
  transform: rotate(-15deg);
  -webkit-transform: rotate(-15deg);
}

.container .img-wrap:nth-child(11n) {
  transform: rotate(-25deg);
  -webkit-transform: rotate(-25deg);
}

.container .img-wrap:hover {
  box-shadow: 15px 15px 20px rgba(50, 50, 50, 0.4);
  transform: rotate(0deg) scale(1.5);
  -webkit-transform: rotate(0deg) scale(1.5);
  z-index: 2;
  position: relative;
  cursor: pointer;
}
</style>

<style lang="scss" scoped>
</style>

